import React from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import Vision from './components/Vision';
import Join from './components/Join';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="app">
      <Header />
      <div className="content-container">
        <Banner />
        <Vision />
        <Join />
        <Footer />
      </div>
      <p className="copyright">&copy; 2025 Scram</p>
    </div>
  );
}

export default App; 