import React, { useState, useEffect } from 'react';
import './Header.css';
import scramLogo from '../assets/logo.png';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  const calculateProgress = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollable = documentHeight - windowHeight;
    const progress = Math.min((window.scrollY / scrollable) * 100, 100);
    setScrollProgress(progress);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
      calculateProgress();
    };

    const handleResize = () => {
      calculateProgress();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToForm = (e) => {
    e.preventDefault();
    const form = document.getElementById('join');
    const emailInput = document.querySelector('input[name="email"]');
    form.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => emailInput?.focus(), 800);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <header className={`header ${isScrolled ? 'with-shadow' : ''}`}>
      <div className="header-content">
        <div 
          className="progress-bar" 
          style={{ width: `${scrollProgress}%` }} 
        />
        <img 
          src={scramLogo} 
          alt="Scram Logo" 
          className="logo" 
          onClick={scrollToTop}
          style={{ cursor: 'pointer' }}
        />
        <button className="waiting-list-button" onClick={scrollToForm}>
          Join the Waiting List
        </button>
      </div>
    </header>
  );
}

export default Header; 