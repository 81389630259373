import React from 'react';
import './Footer.css';
import footerImage from '../assets/footer.png';

function Footer() {
  return (
    <div className="footer">
      <img src={footerImage} alt="Scram Concept Preview" className="footer-image" />
    </div>
  );
}

export default Footer; 