import React from 'react';
import Helmet from 'react-helmet';
import './Join.css';

function Join() {
  return (
    <div className="join">
      <span className="section-number">002</span>
      <div className="join-content">
      <h2 id='join'>Join the Movement</h2>
      <p>Would you like to know more? Sign up: a Scrambot (or maybe one of their human helpers) will be in touch soon...</p>
        <div id="getWaitlistContainer" data-waitlist_id="24681" data-widget_type="WIDGET_1"></div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"/>
          <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
        </Helmet>
      </div>
    </div>
  );
}

export default Join; 