import React from 'react';
import './Banner.css';
import bannerImage from '../assets/banner.png';

function Banner() {
  return (
    <div className="banner">
      <img src={bannerImage} alt="Serious Software, Seriously Fast" className="banner-image" />
      <h1 className="banner-text">Serious Software,<br/> Seriously Fast</h1>
    </div>
  );
}

export default Banner; 