import React, { useState } from 'react';
import './Vision.css';
import visionImage from '../assets/vision.png';
import visionEaster from '../assets/vision-easter.png';

function Vision() {
  const [currentImage, setCurrentImage] = useState(visionImage);

  const handleDoubleClick = () => {
    setCurrentImage(visionEaster);
    setTimeout(() => {
      setCurrentImage(visionImage);
    }, 1500);
  };

  return (
    <div className="vision">
      <div className="vision-content">
        <div className="vision-text">
          <span className="section-number">001</span>
          <h2>Our Vision</h2>
          <p>
            Yeah, you know it: software creation is being transformed. AIs can make apps in
            seconds which previously took months. But the AIs don't get it quite
            right, do they? They need help. Human help.
          </p>
          <p>
            Scram is where humans and AI collaborate to build serious software. This
            isn't just about cool demos: it's everything serious teams need to create,
            test, approve, deploy and iterate complex applications at previously
            unimaginable speed.
          </p>
        </div>
        <div className="vision-image">
          <img 
            src={currentImage} 
            alt="Scram Robot" 
            onDoubleClick={handleDoubleClick}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
    </div>
  );
}

export default Vision; 